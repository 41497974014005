import MountainRange from "assets/mountain_range.svg";
import NolanText from "components/NolanText";

export default function Homepage() {
  return (
    <div className="text-center pt-4 flex-col flex min-h-screen justify-between">
      <div className="space-y-24">
        <div className="text-xs font-mono font-bold space-x-8">
          <span>35.915821 N</span>
          <span>-82.299751 W</span>
        </div>
        <div className="inline-flex flex-col space-y-4 text-primary-700">
          <h1
            style={{ fontSize: "clamp(32px, 6vw, 56px)" }}
            className=" font-display"
          >
            We&rsquo;re Dacey and Tom<span className="sr-only"> Nolan</span>
          </h1>
          {NolanText}
        </div>
      </div>
      {/* <div className="text-2xl max-w-lg mx-auto font-body font-medium">
        Two self-taught software engineers living in the mountains of western
        North Carolina
      </div> */}
      <img
        className="w-full"
        src={MountainRange}
        alt="Mountain range illustration"
        aria-hidden="true"
      />
    </div>
  );
}
