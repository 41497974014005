import Homepage from "components/Homepage";
import Urls from "constants/urls";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={Urls.root} component={Homepage} />
      </Switch>
    </Router>
  );
}
